import { modalController } from '@ionic/core';
import { store } from '@stencil/redux';
import { appAccessDenied, appInstallAppSuccess, appOfflineMigrationComplete, appSync, appSyncSuccess, appTrialExpired, downloadAttachments, downloadMapImagery, downloadMedia, loadApp, reloadApp } from '../store/app/app.actions';
import { getAppOfflineMigrationComplete, getAppsCount, getAuthIsAuth } from '../store/selectors';

import { CoreoApiAppSummary, Unthunk } from '../types';
import { CoreoAPI } from './api.service';
import { imageToImageProxy } from './image.service';

export interface ManageAppsProject {
  id: number;
  name: string;
  icon?: string;
  description: string;
  organisationFolderId: string;
}

export interface ManageAppsFolder {
  id: string;
  name: string;
  parentId: string;
}

export interface ManageAppsOrganisation {
  id: number;
  name: string;
}

export interface ManageAppsOrganisationDetail extends ManageAppsOrganisation {
  folders: ManageAppsFolder[];
  projects: ManageAppsProject[];
}

export interface ManageAppsTreeNode extends ManageAppsFolder {
  children: ManageAppsTreeNode[];
  projects: ManageAppsProject[];
}

export interface ManageAppsOrganisationTree extends ManageAppsOrganisationDetail {
  children: ManageAppsTreeNode[];
}

interface OrganisationListResponse {
  organisations: {
    id: number;
    name: string;
    icon: string;
  }[];
}

interface ProjectSummaryResponse {
  project: {
    name: string;
    icon: string;
    description: string;
    allowContributors: boolean;
  }
}

export class AppService {

  public static instance: AppService = new AppService();

  appSync: Unthunk<typeof appSync>;
  appSyncSuccess: Unthunk<typeof appSyncSuccess>;
  appInstallAppSuccess: Unthunk<typeof appInstallAppSuccess>;
  appAccessDenied: Unthunk<typeof appAccessDenied>;
  appTrialExpired: Unthunk<typeof appTrialExpired>;
  downloadMedia: Unthunk<typeof downloadMedia>;
  downloadAttachments: Unthunk<typeof downloadAttachments>;
  downloadMapImagery: Unthunk<typeof downloadMapImagery>;
  loadApp: Unthunk<typeof loadApp>;
  reloadApp: Unthunk<typeof reloadApp>;

  constructor() {
    store.mapDispatchToProps(this, {
      appSync,
      appSyncSuccess,
      appInstallAppSuccess,
      appAccessDenied,
      appTrialExpired,
      downloadMedia,
      downloadAttachments,
      downloadMapImagery,
      loadApp,
      reloadApp
    });
  }

  getAppsList = async (): Promise<CoreoApiAppSummary[]> => {
    const projectList = `
      query ProjectList {
        projects(order: "name", where: { bespoke: false }) {
          name,
          icon: imageUrl,
          id,
          description,
          allowContributors
        }
      }
    `;

    const result = await CoreoAPI.instance.graphql<{ projects: CoreoApiAppSummary[] }>(projectList, {});

    if (result.errors) {
      throw new Error(result.errors[0].message);
    }

    for (const app of result.data.projects) {
      app.icon = imageToImageProxy(app.icon);
    }
    return result.data.projects;
  }

  getAppSummaryFromAPI = async (id: number): Promise<CoreoApiAppSummary> => {
    const projectSummary = `
      query ProjectSummary($projectId: Int!) {
        project: projectOverview(id: $projectId) {
          name
          icon: imageUrl
          description
          allowContributors
        }
      }
    `;

    const result = await CoreoAPI.instance.graphql<ProjectSummaryResponse>(projectSummary, { projectId: id });

    if (result.errors) {
      throw new Error(result.errors[0].message);
    }

    if (!result.data.project) {
      throw new Error('Project not found');
    }

    const { name, icon, description, allowContributors } = result.data?.project;

    return {
      id,
      name,
      icon: imageToImageProxy(icon),
      description,
      allowContributors
    };
  }

  joinProject = async (projectId: number): Promise<string> => {
    const joinProjectMutation = `
      mutation JoinProject($projectId: Int!){
        joinProject(input:{ projectId: $projectId } ){
          role
        }
      }
    `;

    const response = await CoreoAPI.instance.graphql<{ joinProject: { role: string } }>(joinProjectMutation, {
      projectId
    });
    if (response?.errors?.length > 0) {
      throw response.errors[0];
    }

    const result = response?.data?.joinProject?.role;
    return result;
  }

  public async offlineMigrationTest(force: boolean = false) {
    const state = store.getState();

    const offlineMigrationComplete = getAppOfflineMigrationComplete(state);

    if (offlineMigrationComplete && !force) {
      return;
    }

    const appsCount = getAppsCount(state);
    const isAuth = getAuthIsAuth(state);

    if (!force && !offlineMigrationComplete && appsCount === 0 && !isAuth) {
      store.getStore().dispatch(appOfflineMigrationComplete());
      return;
    }

    const modal = await modalController.create({
      component: 'app-offline-migration',
      backdropDismiss: false,
      keyboardClose: false
    });
    modal.present();
    return modal;
  }

  getOrganisationList = async (): Promise<{ id: number; name: string }[]> => {

    const organisationList = `
      query OrganisationList {
        organisations {
          id
          name
          icon: imageUrl
        }
      }
    `;

    const result = await CoreoAPI.instance.graphql<OrganisationListResponse>(organisationList, {});

    if (result.errors) {
      throw new Error(result.errors[0].message);
    }

    const organisations = result.data.organisations;
    for (const org of organisations) {
      org.icon = imageToImageProxy(org.icon);
    }
    organisations.sort((a, b) => a.name.localeCompare(b.name));
    return organisations;
  }

  getOrganisationDetail = async (id: number): Promise<ManageAppsOrganisationDetail> => {
    const organisationDetail = `
      query OrganistionDetail($id: Int!) {
        organisation(id: $id) {
          id,
          name,
          folders{
            id
            name
            parentId
          }
          projects{
              id
              name
              icon: imageUrl
              description
              organisationFolderId
          }
        }
      }
    `;

    const result = await CoreoAPI.instance.graphql<{ organisation: ManageAppsOrganisationDetail }>(organisationDetail, { id });
    const org = result.data.organisation;
    org.projects.sort((a, b) => a.name.localeCompare(b.name));
    for (const project of org.projects) {
      project.icon = imageToImageProxy(project.icon);
    }
    return org;
  }

  getContributorProjects = async (): Promise<ManageAppsTreeNode | null> => {
    const query = `query AppGetContributorProjects {
      viewer {
        projects: contributorProjects {
              id
              name
              icon: imageUrl
              description
              organisationFolderId
        }
      }
    }`;
    const result = await CoreoAPI.instance.graphql<{ viewer: { projects: CoreoApiAppSummary[] } }>(query, {});
    if (result.data.viewer.projects.length === 0) {
      return null;
    }

    result.data.viewer.projects.sort((a, b) => a.name.localeCompare(b.name));
    return {
      id: null,
      name: 'Contributor Projects',
      parentId: null,
      children: [],
      projects: result.data.viewer.projects.map(p => ({
        ...p,
        organisationFolderId: null,
        icon: imageToImageProxy(p.icon)
      }))
    };
  }

  public buildOrganisationTree(organisation: ManageAppsOrganisationDetail): ManageAppsTreeNode {
    return this.buildTree(organisation.projects, organisation.folders);
  }


  private buildTree(projects: ManageAppsProject[], folders: ManageAppsFolder[], folderId: string | null = null): ManageAppsTreeNode {
    const filteredProjects = projects.filter(p => p.organisationFolderId === folderId);
    const children = folders.filter(f => f.parentId === folderId).map(f => this.buildTree(projects, folders, f.id));
    const folder: ManageAppsFolder = folders.find(f => f.id === folderId) ?? { id: null, name: '', parentId: null };
    return { ...folder, projects: filteredProjects, children }
  };

  findTree(tree: ManageAppsTreeNode, id: string): ManageAppsTreeNode {
    if (tree.id === id) {
      return tree;
    }
    if (tree.children) {
      for (let i = 0; i < tree.children.length; i++) {
        const found = this.findTree(tree.children[i], id);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

}
